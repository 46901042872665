<template>
  <div v-if="entry">
    <router-link
      :to="entry.heroCtaUrl ? localizedRoute(entry.heroCtaUrl) : '/'"
      class="relative z-10 hover:text-white transition-all block rc-height"
    >
      <div class="rc-overlay">
        <h2
          v-if="entry.heroHeadingOne"
          :class="headingOneStyles"
          class="rc-heading-one uppercase font-featured text-white leading-none m-0"
        >
          {{ entry.heroHeadingOne }}
        </h2>

        <h3
          v-if="entry.heroHeadingTwo"
          :class="headingTwoStyles"
          class="rc-heading-two uppercase font-featured text-white leading-none m-0"
        >
          {{ entry.heroHeadingTwo }}
        </h3>

        <div v-if="entry.heroCtaText" class="inline-block mt-5">
          <div
            class="flex items-center gap-2 text-lg bg-gray-900 text-white px-5 py-4 uppercase font-featured font-bold"
          >
            <span>
              {{ entry.heroCtaText }}
            </span>
            <ArrowRight class="h-4" />
          </div>
        </div>
      </div>

      <video
        v-if="hasVideo"
        :poster="poster"
        class="object-cover bg-blend-multiply w-full rc-height"
        playsinline
        autoplay
        loop
        muted
      >
        <!-- MOBILE PORTRAIT (up to 767px) -->
        <source
          v-if="mobileVideo"
          :src="mobileVideo"
          type="video/mp4"
          media="(max-width: 767px)"
          width="430"
          height="430"
        />
        <!-- MOBILE LANDSCAPE (640px to 767px) -->
        <source
          v-if="desktopVideo"
          :src="desktopVideo"
          type="video/mp4"
          media="(min-width: 640px) and (max-width: 767px) and (orientation: landscape)"
          width="932"
          height="430"
        />
        <!-- TABLET PORTRAIT (768px to 1024px) -->
        <source
          v-if="mobileVideo"
          :src="mobileVideo"
          type="video/mp4"
          media="(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)"
          width="768"
          height="1024"
        />
        <!-- TABLET LANDSCAPE (1024px and above) -->
        <source
          v-if="desktopVideo"
          :src="desktopVideo"
          type="video/mp4"
          media="(min-width: 1024px) and (orientation: landscape)"
          width="1024"
          height="768"
        />
        <!-- LARGE TABLET PORTRAIT (1024px to 1366px) -->
        <source
          v-if="mobileVideo"
          :src="mobileVideo"
          type="video/mp4"
          media="(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)"
          width="1024"
          height="768"
        />
        <!-- LARGE TABLET LANDSCAPE (1366px and above) -->
        <source
          v-if="desktopVideo"
          :src="desktopVideo"
          type="video/mp4"
          media="(min-width: 1366px) and (orientation: landscape)"
          width="1024"
          height="768"
        />
        <!-- LARGE DESKTOP (1367px and above) -->
        <source
          v-if="desktopVideo"
          :src="desktopVideo"
          type="video/mp4"
          media="(min-width: 1367px)"
          width="1200"
          height="800"
        />
      </video>

      <picture
        v-if="!hasVideo"
        class="object-cover bg-blend-multiply w-full rc-height"
      >
        <!-- MOBILE PORTRAIT (up to 767px) -->
        <source
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(max-width: 767px)"
          width="430"
          height="932"
        />
        <!-- MOBILE LANDSCAPE (640px to 932px) -->
        <source
          v-if="desktopImage"
          :srcset="desktopImage"
          media="(min-width: 640px) and (max-width: 767px) and (orientation: landscape)"
          width="932"
          height="430"
        />
        <!-- TABLET PORTRAIT (768px to 1024px) -->
        <source
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)"
          width="768"
          height="1024"
        />
        <!-- TABLET LANDSCAPE (1024px and above) -->
        <source
          v-if="desktopImage"
          :srcset="desktopImage"
          media="(min-width: 1024px) and (orientation: landscape)"
          width="1024"
          height="768"
        />
        <!-- LARGE TABLET PORTRAIT (1024px to 1366px) -->
        <source
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)"
          width="1024"
          height="768"
        />
        <!-- LARGE TABLET LANDSCAPE (1366px and above) -->
        <source
          v-if="desktopImage"
          :srcset="desktopImage"
          media="(min-width: 1366px) and (orientation: landscape)"
          width="1024"
          height="768"
        />
        <!-- LARGE DESKTOP (1367px and above) -->
        <source
          v-if="desktopImage"
          :srcset="desktopImage"
          media="(min-width: 1367px)"
          width="1200"
          height="800"
        />
        <img :src="desktopImage" alt="" class="object-cover w-full rc-height" />
      </picture>
    </router-link>
  </div>
</template>

<script>
import config from 'config';
import { mapGetters } from 'vuex';
import ArrowRight from '../../../theme/bikebuilder/svg/ui/ArrowRight.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  components: {
    ArrowRight
  },
  computed: {
    ...mapGetters('craftCms', ['homeHero']),
    entry() {
      if (!this.homeHero) return null;
      return this.homeHero.entries.find((entry) => entry.status === 'live');
    },
    desktopImage() {
      if (
        !this.entry ||
        !this.entry.heroImage ||
        !this.entry.heroImage.length
      ) {
        return '';
      }

      const image = this.entry.heroImage[0].url;
      return `${config.speedSizeUrl}/${image}`;
    },
    desktopVideo() {
      const entry = this.entry;
      if (!entry || !entry.heroVideo || !entry.heroVideo.length) {
        return '';
      }
      const video = entry.heroVideo[0].url;
      return `${config.speedSizeUrl}/${video}`;
    },
    mobileImage() {
      if (!this.entry) return '';
      const image =
        this.entry.mobileImage && this.entry.mobileImage.length
          ? this.entry.mobileImage[0].url
          : this.image;
      return image ? `${config.speedSizeUrl}/${image}` : '';
    },
    mobileVideo() {
      if (!this.entry) return '';
      const video =
        this.entry.mobileVideo && this.entry.mobileVideo.length
          ? this.entry.mobileVideo[0].url
          : this.video;
      return video ? `${config.speedSizeUrl}/${video}` : '';
    },
    poster() {
      return this.mobileImage;
    },
    headingOneStyles() {
      const hasStyle = this.entry && this.entry.headingStyles.length;
      const isBold = this.entry.headingStyles.find(
        (style) => style === 'headingOneBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    headingTwoStyles() {
      const hasStyle = this.entry && this.entry.headingStyles.length;
      const isBold = this.entry.headingStyles.find(
        (style) => style === 'headingTwoBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    hasVideo() {
      return !!(this.video || this.mobileVideo);
    },
    hasImage() {
      return !!(this.image || this.mobileImage);
    }
  }
};
</script>

<style scoped>
/* Override the ayko styles */
#app a:not(.menu-link):not(.action):not(.link):hover span {
  color: #fff;
}
.rc-height {
  height: 50vh;

}
.rc-heading-one {
  font-size: 28px;
}
.rc-heading-two {
  font-size: 28px;
}
.rc-overlay {
  top: 70%;
  left: 50%;
  width: 90vw;
  text-align: left;
  position: absolute;
  transform: translate(-50%, -50%);
}
/*
  Tailwind Breakpoints
  * sm	640px
  * md	768px
  * lg	1024px
  * xl	1280px
  * 2xl 1536px
*/

/* MOBILE PORTRAIT */
@media (max-width: 639px) and (orientation: portrait) {
  .rc-height {
    height: 50vh;
  }
  .rc-heading-one {
    font-size: 28px;
  }
  .rc-heading-two {
    font-size: 28px;
  }
  .rc-overlay {
    top: 70%;
  }
}
/* MOBILE LANDSCAPE */
@media (min-width: 640px) and (max-width: 932px) and (orientation: landscape) {
  .rc-height {
    height: 70vh;
  }
  .rc-heading-one {
    font-size: 28px;
  }
  .rc-heading-two {
    font-size: 28px;
  }
  .rc-overlay {
    top: 70%;
  }
}
/* TABLET PORTRAIT */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .rc-height {
    height: 90vh;
  }
  .rc-heading-one {
    font-size: 38px;
  }
  .rc-heading-two {
    font-size: 38px;
  }
  .rc-overlay {
    top: 70%;
  }
}
/* TABLET LANDSCAPE */
@media (min-width: 1024px) and (orientation: landscape) {
  .rc-height {
    height: 80vh;
  }
  .rc-heading-one {
    font-size: 38px;
  }
  .rc-heading-two {
    font-size: 38px;
  }
  .rc-overlay {
    top: 70%;
  }
}
/* LARGE TABLET PORTRAIT */
@media (min-width: 1024px) and (orientation: portrait) {
  .rc-height {
    height: 90vh;
  }
  .rc-heading-one {
    font-size: 38px;
  }
  .rc-heading-two {
    font-size: 38px;
  }
  .rc-overlay {
    top: 70%;
  }
}
/* LARGE TABLET LANDSCAPE */
@media (min-width: 1366px) and (orientation: landscape) {
  .rc-height {
    height: 50vh;
  }
  .rc-heading-one {
    font-size: 38px;
  }
  .rc-heading-two {
    font-size: 38px;
  }
  .rc-overlay {
    top: 70%;
  }
}

/* LARGE DESKTOP */
@media (min-width: 1367px) {
  .rc-height {
    height: 70vh;
  }
  .rc-heading-one {
    font-size: 70px;
  }
  .rc-heading-two {
    font-size: 70px;
  }
  .rc-overlay {
    top: 70%;
  }
}

/* EXTRA LARGE DESKTOP */
@media (min-width: 1536px) {
  .rc-height {
    height: 80vh;
  }
}
</style>
